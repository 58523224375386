import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  displayName: string = '';
  vendorName: string = '';
  isUserLogin: boolean = false;
  isVendor: boolean = false;
 
  constructor(private router: Router) {
    if(window.sessionStorage.getItem('uname')){
      this.isUserLogin = true;
      this.displayName = window.sessionStorage.getItem('uname');
    }
    else if (window.sessionStorage.getItem('vname') != null && window.sessionStorage.getItem('vname') != '' &&
      window.sessionStorage.getItem('vname') != undefined) {
        if(window.location.pathname.indexOf("landing") > -1 || window.location.pathname.indexOf("login") > -1  || window.location.pathname.indexOf("register") > -1){
          this.vendorName = '';
          this.isUserLogin = false;
          this.isVendor = false;
        }else{
          this.vendorName = window.sessionStorage.getItem('vname');
          this.isVendor = true;
          this.isUserLogin = false;
        }
    }
    else {
      this.isUserLogin = false;
      this.displayName = window.sessionStorage.getItem('uname');
    }
  }

  ngOnInit() {
  }

  terminateSession() {
    window.sessionStorage.clear();
    //this.router.navigate(['/landing']);
    window.location.href='/landing';
  }

  resetPassword() {
    this.router.navigate(['/resetpassword']);
  }
  myAccount() {
    this.router.navigate(['/myaccount']);
  }
}
