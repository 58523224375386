import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizationComponent } from './authorization/authorization.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth.guard.service';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ResetNewpasswordComponent } from './resetnewpassword/resetnewpassword.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsofuseComponent } from './termsofuse/termsofuse.component';
import { FaqComponent } from './faq/faq.component';
import { PricingComponent } from './pricing/pricing.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { RegistrationComponent } from './registration/registration.component';
import { LandingComponent } from './landing/landing.component';
import { UserProfileComponent } from './userprofile/userprofile.component';
import { activatelinkComponent } from './activatelink/activatelink.component';

const routes: Routes = [
  { path: 'login', component: AuthorizationComponent },
  { path: 'register', component:RegistrationComponent},
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'resetpassword', component: ResetpasswordComponent, canActivate: [AuthGuard] },
  { path: 'privacy', component:PrivacyComponent},
  { path: 'terms', component:TermsofuseComponent},
  { path: 'faq', component:FaqComponent},
  { path: 'pricing', component:PricingComponent},
  { path: 'about', component:AboutusComponent},
  { path: 'contact', component:ContactusComponent},
  { path: 'landing', component:LandingComponent },
  { path: 'myaccount', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'verifylink/:userId', component: ResetNewpasswordComponent},
  { path: 'activatelink/:userId', component: activatelinkComponent},
  { path: 'home/:dashboardId', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
